.ag-cell-wrapper.ag-row-group {
  .ag-group-contracted,
  .ag-group-expanded {
    width: 100%;

    .ag-icon.ag-icon-contracted,
    .ag-icon.ag-icon-expanded {
      width: 100%;
      text-align: center;
    }
  }

  .ag-group-value {
    width: 0;
  }

  .ag-group-checkbox.ag-invisible {
    margin: 0;
  }
}

.ag-Table__title {
  padding: 4px 4px 4px 15px;
}

#root .flexRight {
  margin-left: auto;
}
