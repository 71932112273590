.status {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

#code {
  font-weight: bold;
  font-size: 40px;
}

#text {
  font-size: 20px;
}
