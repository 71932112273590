html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  display: flex;
}
