@media all {
  .page-break {
    display: none;
  }
}

@media screen {
  .print-show,
  #placeholder-button {
    display: none;
  }
}

@media screen {
  #show-grid {
    display: contents;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 0;
    display: block;
    page-break-after: always;
  }
  #save-button,
  #send-button,
  #print-button,
  #add-content,
  #add-button-0,
  #add-button-1,
  #add-button-2,
  #enable-0,
  #enable-1,
  #enable-2 {
    display: none;
  }
  .print-hide {
    display: none;
  }
  #hide-grid {
    display: contents;
  }
}

@page {
  size: landscape;
  margin: 1%;
}
