.column {
  float: left;
  width: 33.33%;
  font-size: 16px;
}

.gi-header {
  font-size: 16px;
  font-weight: bold;
  width: 30ch;
  text-align: center;
}
