.MuiButton-root {
  .MuiButton-label {
    font-weight: 700;
    letter-spacing: 1.25px;
  }

  &.MuiButton-containedSecondary {
    background-color: #980f5f;

    &:hover {
      background-color: #a0216b;
    }
  }
}

.tooltip {
  position: absolute;
  pointer-events: none;

  &__content {
    padding: 9.5px 20px 9.5px 15px;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  }
}

.image-viewer {
  display: inline-block;
  width: 100%;

  &__icon {
    vertical-align: middle;
    max-width: 36px;
    max-height: 48px;
  }

  &__thumb {
    position: absolute;
    z-index: 10;
    background-color: #fff;
    line-height: 0;
    max-width: 124px;
    max-height: 184px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
