@import '~@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
@import '~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material/sass/ag-theme-material.scss';

$table-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

$success-base: #e1fbeb;

.ag-overlay-loading-inner-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

@mixin ag-table-shared {
  box-shadow: $table-shadow;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .centerIcon {
    height: 46px; /*can be anything*/
    width: 48px; /*can be anything*/
    display: inline-block;
    vertical-align: top; /*not required*/
    //border: 1px solid black; /*not required*/
    position: relative;

    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .ag-cell-data-changed {
    background-color: $success-base !important;
  }

  .ag-icon-checkbox-checked {
    color: #980f5f;
  }

  // Word wrap long LOV when necessary
  .ag-rich-select-row {
    white-space: normal !important;
  }
}

.ag-Table {
  &__container {
    height: 100%;
    box-shadow: $table-shadow;

    > .ag-theme-material {
      width: 100%;
      @include ag-table-shared;

      .ag-popup > div {
        z-index: 10;
      }

      .ag-rich-select-list {
        height: auto;

        .ag-virtual-list-viewport {
          // show up to 7 items in dropdown
          max-height: $ag-rich-select-item-height * 6.5;

          .ag-virtual-list-container {
            min-height: $ag-rich-select-item-height;
          }
        }
      }
    }

    .ag-details-row {
      width: auto;
      display: flex;

      .ag-details-grid {
        width: auto;
        @include ag-table-shared;

        .ag-root-wrapper {
          overflow: inherit;

          .ag-root {
            width: auto;
          }
        }
      }
    }

    .ag-full-width-row {
      overflow: inherit;
      z-index: 10;
    }

    /* This is needed if we use embedFullWidthRows. It hides the extra table.
    .ag-cell-last-left-pinned .ag-details-row {
      display: none;
    }
    */
  }

  &__container .success {
    background-color: $success-base;
    &:hover {
      background-color: #d2f3d7;
    }
    &.ag-cell-focus {
      background-color: #b1e9b9;
    }
  }

  &__container .softDanger {
    background-color: #fffae5;
    &:hover {
      background-color: #fff8dd;
    }
    &.ag-cell-focus {
      background-color: #fff7d3;
    }
  }

  &__container .hardDanger {
    background-color: #fbe1e2;
    &:hover {
      background-color: #f7d3d5;
    }
    &.ag-cell-focus {
      background-color: #f6b9bb;
    }
  }

  &__title {
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.25px;
    background-color: #5d1049;
    padding: 6px 6px 6px 25px;
    box-shadow: $table-shadow;
  }

  &__header.MuiTypography-body2 {
    font-weight: 600;
  }

  &__column-group.MuiTypography-body2 {
    font-weight: 600;

    .ag-header-expand-icon .ag-icon {
      cursor: pointer;
    }
  }
}

.ag-center-cols-container {
  overflow: auto;
}
