.column {
  float: left;
  width: 33.33%;
  font-size: 16px;
  font-weight: bold;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}
